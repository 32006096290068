import { useEffect } from 'react';
import { Container, Card, TablePagination } from '@mui/material';
import TicketToolbar from '../toolbar/Toolbar';
import Searchbar from '../searchbar/Searchbar';
import TicketItem from '../list_item/TicketItem';
import Page from 'components/page/Page';
import { TICKET_ADMIN_REFETCH } from 'adapters/queries/TicketAdminRefetch';
import GenericTable from 'components/common_datapage/GenericTable';
import usePaginationQuery from 'components/common_datapage/usePaginationQuery';

const TicketsList = () => {
  const columns = [
    { key: 'customerDetails', label: 'Customer Details' },
    { key: 'location', label: 'Location' },
    { key: 'subject', label: 'Subject' },
    { key: 'company', label: 'Company' },
    { key: 'description', label: 'Description' },
    { key: 'priority', label: 'Priority' },
    { key: 'code', label: 'Code' },
    { key: 'createdAt', label: 'Created At' }
  ];

  // Custom hook for handling query, pagination, and search
  const {
    data, loading, onPageChange, onRowsPerPageChange, setSearchQuery, setPage, page, count, refetch
  } = usePaginationQuery(TICKET_ADMIN_REFETCH, {}, 'listTickets');

  const tickets = data?.listTickets || {};
  const totalCount = tickets.totalCount || 0;
  const list = tickets.edges?.map((edge: any) => edge.node).filter((node: any) => node != null) || [];

  useEffect(() => {
    setPage(0); 
    refetch(); 
  }, [refetch]);

  return (
    <Page title="Tickets">
      <Container maxWidth="xl">
        <TicketToolbar />
        <Searchbar onSearch={setSearchQuery} />
        <Card sx={{ mt: 3 }}>
          <GenericTable
            columns={columns}
            data={list}
            totalCount={totalCount}
            loading={loading}
            renderItem={(node: any) => <TicketItem key={node.id} ticket={node} />}
          />
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={count}
            onRowsPerPageChange={onRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default TicketsList;
